import React, { Suspense } from "react";
import GlobalModule from "../../global-module";
import ApplicationError from "../../common-module/components/application-error";
import ToasterMessages from "../../common-module/components/toaster";
import Notificationbar from "../components/notification-bar";

export const componentTemplate = (com) => {
  return (
    <Suspense fallback={<div> </div>}>
      <form>
        {com.state.activateModule && (
          <div>
            {com.state.downtimeData && (
              <Notificationbar
                downtimeData={com.state.downtimeData}
                enableModal={com.enableModal}></Notificationbar>
            )}
            <GlobalModule></GlobalModule>
          </div>
        )}
      </form>
      {com.state.userEmail && <ApplicationError userEmail={com.state.userEmail}></ApplicationError>}
      <ToasterMessages></ToasterMessages>
    </Suspense>
  );
};
