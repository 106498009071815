import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Wrapper from "./app/common-module/components/wrapper";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/common-module.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./assets/translations/i18n";

/**
 * Add your comments here!!
 */

let role = window.location.pathname.split("/")[1].toLowerCase();
(async () => {
  Promise.all([
    role == "admin" && import("./assets/css/admin-module.css"),
    role == "candidate" && import("./assets/css/candidate-module.css"),
    role == "scheduler" && import("./assets/css/scheduler-module.css"),
    (role == "interviewer" || role == "landing") && import("./assets/css/interviewer-module.css")
  ]).then(() => {
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <React.StrictMode>
        <Suspense>
          <I18nextProvider i18n={i18n}>
            <Wrapper />
          </I18nextProvider>
        </Suspense>
      </React.StrictMode>
    );
    reportWebVitals();
  });
})();
