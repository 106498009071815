import axios from "axios";
import { appSettings } from "../../../assets/config/app-config";
import Emitter from "../services/event-emitter";
import { appEvents, sessionKEYS } from "../services/constants";

/**
 * add comment here
 */

const httpAxios = axios.create({
  baseURL: appSettings.apiUrl
});

/**
 * add comment here
 */

const requestHandler = (request) => {
  request.headers.ContentType = "application/json";
  if (window.isAuthenticationEnabled) {
    request.headers.Authorization = `Bearer ${sessionStorage.getItem(sessionKEYS.idToken)}`;
  }
  return request;
};

/**
 * add comment here
 */

function responseHandler(response) {
  return response;
}

/**
 * add comment here
 */

const errorHandler = (error) => {
  Emitter.emit(appEvents.openErrorPopup, error?.response?.status);
  return Promise.reject(error);
};

/**
 * add comment here
 */

httpAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

/**
 * add comment here
 */

httpAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default httpAxios;
