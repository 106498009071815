import React from "react";
import httpAxios from "../intercepter/intercepter.service";

export class HttpService extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   * Constructs a `GET` request that interprets the body as a JSON object and returns the response body as a JSON object.
   */

  getAll(url) {
    return httpAxios.get(url).then((response) => {
      return response.data;
    });
  }

  /**
   * Constructs a `POST` request that interprets the body as a JSON object and returns the response body as a JSON object.
   */

  postData(url, data) {
    return httpAxios.post(url, data).then((response) => {
      return response.data;
    });
  }
}
