import React from "react";
import { SharedService } from "../services/common.service";
import { appEvents, routePaths } from "../services/constants";
import Emitter from "../services/event-emitter";
import { componentTemplate } from "../templates/application-error-html";
import { getSupportUrl } from "../services/constants";

class ApplicationError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: 0,
      openModal: false,
      homeLink: null,
      className: "",
      supportLink: ""
    };
    this.loadServices();
  }

  /**
   * Add your comments here!!
   */

  componentDidMount() {
    this.registerEvents();
    this.getRole();
    this.getSupport();
  }

  /**
   *Load Services
   */

  loadServices() {
    this.sharedService = new SharedService();
  }

  /**
   *Load Services
   */

  getRole() {
    let role = window.location.pathname?.split("/")[1].toLowerCase();
    role = role == "landing" ? "interviewer" : role;
    this.setState({ className: role });
  }

  /**
   * Add your comments here!!
   */

  registerEvents() {
    Emitter.on(appEvents.openErrorPopup, (data) => {
      this.bindData(data);
      this.resetEvents();
    });
  }

  /**
   *Reset Events
   */

  resetEvents() {
    Emitter.off(appEvents.openErrorPopup);
    this.registerEvents();
  }

  /**
   * Add your comments here!!
   */

  openModal(value) {
    this.setState({ openModal: value });
  }

  /**
   * Add your comments here!!
   */

  bindData(data) {
    this.setState({ code: data }, () => {
      this.removeLoader();
      this.getRoleFromURL();
      this.openModal(true);
    });
  }

  /**
   * Add your comments here!!
   */

  removeLoader() {
    document.getElementById("page-loader")?.remove();
  }

  /**
   * Add your comments here!!
   */

  getRoleFromURL() {
    let path = this.sharedService.getRoleFromURL();
    this.setState({ homeLink: routePaths[path]?.dashboard });
  }

  /**
   * Add your comments here!!
   */

  gotoHome() {
    window.location.href = window.location.origin + routePaths.landing.dashboard;
  }

  /**
   * Add your comments here!!
   */

  getSupport() {
    const isInternal = this.sharedService.isAccentureEmailId(this.props.userEmail?.split("@")[1]);
    isInternal && this.setState({ supportLink: getSupportUrl.internal });
  }

  /**
   * Add your comments here!!
   */

  renewAccount() {
    this.sharedService.getSilentToken();
  }

  /**
   * Add your comments here!!
   */

  logout() {
    this.sharedService.logOut();
  }

  /**
   * Add your comments here!!
   */

  render() {
    return componentTemplate(this);
  }
}
export default ApplicationError;
