/* eslint-disable*/
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

/**
 * Add your comments here!!
 */

let dashboard = window.location.pathname.split("/")[1].toLowerCase();

/**
 * Add your comments here!!
 */

function getInterviewerAndSchedulerLanguages() {
  let english, spanish, portuguese, spanishLatam, chinese, japanese;
  let array = [
    import("./" + dashboard + "/english").then((data) => (english = data)),
    import("./" + dashboard + "/spanish").then((data) => (spanish = data)),
    import("./" + dashboard + "/portuguese").then((data) => (portuguese = data)),
    import("./" + dashboard + "/spanish-latam").then((data) => (spanishLatam = data)),
    import("./" + dashboard + "/chinese").then((data) => (chinese = data)),
    import("./" + dashboard + "/japanese").then((data) => (japanese = data))
  ];
  (async () => {
    Promise.all(array).then(() => {
      i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          resources: {
            EN: { translation: english },
            ES: { translation: spanish },
            PT: { translation: portuguese },
            ESL: { translation: spanishLatam },
            JA: { translation: japanese },
            CN: { translation: chinese }
          },
          fallbackLng: "EN",
          whitelist: ["ES", "PT", "ESL", "JA", "CN"]
        });
    });
  })();
}

/**
 * Add your comments here!!
 */

function getCandidatesLanguages() {
  (async () => {
    let english, spanish, italian, french, dutch, polish, portuguese, spanishLatam, japanese;
    let array = [
      import("./" + dashboard + "/english").then((data) => (english = data)),
      import("./" + dashboard + "/spanish").then((data) => (spanish = data)),
      import("./" + dashboard + "/italian").then((data) => (italian = data)),
      import("./" + dashboard + "/french").then((data) => (french = data)),
      import("./" + dashboard + "/dutch").then((data) => (dutch = data)),
      import("./" + dashboard + "/polish").then((data) => (polish = data)),
      import("./" + dashboard + "/portuguese").then((data) => (portuguese = data)),
      import("./" + dashboard + "/spanish-latam").then((data) => (spanishLatam = data)),
      import("./" + dashboard + "/japanese").then((data) => (japanese = data))
    ];
    Promise.all(array).then(() => {
      i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          resources: {
            EN: { translation: english },
            ES: { translation: spanish },
            IT: { translation: italian },
            FR: { translation: french },
            DUT: { translation: dutch },
            PL: { translation: polish },
            PT: { translation: portuguese },
            ESL: { translation: spanishLatam },
            JA: { translation: japanese }
          },
          fallbackLng: "EN",
          whitelist: ["ES", "IT", "FR", "DUT", "PL", "PT", "ESL", "JA"]
        });
    });
  })();
}

/**
 * Add your comments here!!
 */

if (dashboard == "scheduler" || dashboard == "interviewer") {
  getInterviewerAndSchedulerLanguages();
} else if (dashboard == "candidate") {
  getCandidatesLanguages();
}

export default i18n;
