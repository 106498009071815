import React from "react";
import { SharedService } from "../services/common.service";
import { componentTemplate } from "../templates/notification-bar-html";

class Notificationbar extends React.Component {
  constructor(props) {
    super(props);
    this.loadCustomServices();
  }

  /**
   * Add your comments here!!
   */

  closeModal = () => {
    this.props.enableModal(null);
  };

  /**
   * Add your comments here!!
   */

  loadCustomServices() {
    this.sharedService = new SharedService();
  }

  render() {
    return componentTemplate(this);
  }
}
export default Notificationbar;
