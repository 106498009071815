/* eslint-disable indent */
import React from "react";
import {
  accEmails,
  apiLinks,
  appEvents,
  customMsg,
  dtFormat,
  msgMode,
  routePaths,
  sessionKEYS,
  userTypes
} from "./constants";
import { HttpService } from "./data.service";
import { AzureAuthService } from "./azure.service";
import Emitter from "./event-emitter";
import { DateService } from "./date.service";
import parse from "html-react-parser";
import { locale } from "primereact/api";

export class SharedService extends React.Component {
  constructor(props) {
    super(props);
    this.channel = null;
    this.bindServices();
    this.resetBroadcast();
    this.registerWindowEvents();
  }

  /**
   * Encrypt data
   */

  bindServices() {
    this.dataService = new HttpService();
    this.adService = new AzureAuthService();
    this.dateService = new DateService();
  }

  /**
   * Add your comments here!!
   */

  registerWindowEvents() {
    this.channel.addEventListener("message", () => {
      this.adService.logout();
    });
  }

  /**
   * Add your comments here!!
   */

  resetBroadcast() {
    const userId = this.getUsername();
    this.channel?.removeEventListener(userId, null);
    this.channel = new BroadcastChannel(userId);
  }

  /**
   * Encrypt data
   */

  getUserRoleDetails() {
    return this.dataService.getAll(apiLinks.getUserRoles);
  }

  /**
   * Add your comments here!!
   */

  getConfigurationData(data) {
    return this.dataService.postData(apiLinks.getConfigurationData, data);
  }

  /**
   * Add your comments here!!
   */

  getPrivacyStatement(data) {
    return this.dataService.postData(apiLinks.getPrivacyStatement, data);
  }

  /**
   * Encrypt data
   */

  encryptData(data) {
    return data && data !== "null" ? window.btoa(JSON.stringify(data)) : null;
  }

  /**
   * Decrypt data
   */

  decryptData(data) {
    return data && data !== "null" ? JSON.parse(window.atob(data)) : null;
  }

  /**
   * Save data to session
   */

  keepInSession(key, data) {
    sessionStorage.setItem(key, this.encryptData(data));
  }

  /**
   * Fetch data from session
   */

  getFromSession(key) {
    return this.decryptData(sessionStorage.getItem(key));
  }

  /**
   * Fetch data from session
   */

  removeSession(key) {
    return sessionStorage.removeItem(key);
  }

  /**
   * Alert message
   */

  async isAuthenticated() {
    let azure = await this.adService.isAuthenticated();
    if (azure) {
      return true;
    } else {
      this.clearAll();
      this.adService.msalLogin();
    }
  }

  /**
   * Add your comments here!!
   */

  alert(message, mode, summary, banner) {
    Emitter.emit(appEvents.toastMessages, {
      message: message,
      mode: mode,
      summary: summary,
      banner: banner
    });
  }

  /**
   * Add your comments here!!
   */

  requiredAlert() {
    this.alert(customMsg.required, msgMode.warning);
  }

  /**
   * Add your comments here!!
   */

  duplicateAlert() {
    this.alert(customMsg.duplicate, msgMode.warning);
  }

  /**
   * Add your comments here!!
   */

  errorAlert() {
    this.alert(customMsg.errorOccured, msgMode.error);
  }

  /**
   * Add your comments here!!
   */

  getUserAccount() {
    return this.adService.getAccount();
  }

  /**
   *A lifecycle
   */

  groupBy(source, property, key, custom) {
    let groups = new Set(source.map((item) => item[property]));
    let result = [];
    groups.forEach((g) => {
      if (g) {
        if (custom == false) {
          result[g.replace(/ /g, "")] = source.filter((i) => i[property] === g);
        } else {
          result.push({
            name: g,
            [key]: source.filter((i) => i[property] === g)
          });
        }
      }
    });
    return result;
  }

  /**
   * Add your comments here!!
   */

  clearAll() {
    sessionStorage.clear();
    localStorage.clear();
  }

  /**
   * Add your comments here!!
   */

  getBrowserZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * Add your comments here!!
   */

  updateBrowserTimeZone() {
    this.keepInSession(sessionKEYS.currentTimeGenaricName, this.getBrowserZone());
  }

  /**
   * Add your comments here!!
   */

  isRouteValid() {
    let role = this.getRoleFromURL();
    return routePaths[role] ? true : false;
  }

  /**
   * Add your comments here!!
   */

  getRoleFromURL() {
    return window.location.pathname.split("/")[1];
  }

  /**
   * Add your comments here!!
   */

  getPageFromURL() {
    return window.location.pathname.split("/")[2];
  }

  /**
   * Add your comments here!!
   */

  getUserType() {
    return this.adService.getUserType();
  }

  /**
   * Add your comments here!!
   */

  isAccentureEmailId(email) {
    return accEmails.includes(email);
  }

  /**
   * Add your comments here!!
   */

  isAnInternalCandidate() {
    if (this.getUserType() == userTypes.external) {
      let email = this.getUserEmailID()?.split("@")[1];
      return this.isAccentureEmailId(email);
    }
    return false;
  }

  /**
   * Add your comments here!!
   */

  queryNavigation = (type, value, alert) => {
    let param = new URLSearchParams(window.location.search);
    param.set(type, value);
    if (!alert) {
      window.location.search = param.toString();
    } else {
      setTimeout(() => {
        window.location.search = param.toString();
      }, 5000);
    }
  };

  /**
   * Add your comments here!!
   */

  removeQueryString(path) {
    window.history.replaceState({}, "", window.location.origin + path);
  }

  /**
   * Add your comments here!!
   */

  replaceQueryString(key, value) {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState(null, "", url.toString());
  }

  /**
   * Add your comments here!!
   */

  changePanelHeader(text) {
    document.getElementsByClassName("p-checkbox-box")[0]?.setAttribute("data-value", text);
  }

  /**
   * Add your comments here!!
   */

  refreshPage() {
    window.location.reload();
  }

  /**
   * ***********************New Date Functions**********************
   */

  /**
   * Add your comments here!!
   */

  getShortMonth(date) {
    return this.dateService.getShortMonth(date);
  }

  /**
   * Add your comments here!!
   */

  getShortWeek(date) {
    return this.dateService.getShortWeek(date);
  }

  /**
   * Add your comments here!!
   */

  getLongWeek(date) {
    return this.dateService.getLongWeek(date);
  }

  /**
   * Add your comments here!!
   */

  getTimeZoneGenaricName() {
    let timezone = this.getFromSession(sessionKEYS.currentTimeGenaricName);
    return timezone ? timezone : "";
  }

  /**
   * Add your comments here!!
   */

  getTimezoneId() {
    return this.getFromSession(sessionKEYS.currentTimeZoneId);
  }

  /**
   * Add your comments here!!
   */

  getTimeZoneSTDName() {
    return this.getFromSession(sessionKEYS.currentTimeStdName);
  }

  /**
   * Add your comments here!!
   */

  convertToCurrentZone(date, dateFormat, zone) {
    zone = zone ? zone : this.getTimeZoneGenaricName();
    return this.dateService.convertToTimezone(date, zone);
  }

  /**
   * Add your comments here!!
   */

  saveTimeConfigInLocal(data) {
    this.keepInSession(sessionKEYS.currentTimeZoneId, data.timezoneId);
    this.keepInSession(sessionKEYS.currentTimeGenaricName, data.timezoneGenericName);
    this.keepInSession(sessionKEYS.currentTimeStdName, data.timezoneStandardName);
    data.languageId && this.keepInSession(sessionKEYS.currentLanguage, data.languageId);
    this.updateCalenderLocale(data.languageId);
  }

  /**
   * Add your comments here!!
   */

  updateCalenderLocale(languageId) {
    languageId && locale(languageId);
  }

  /**
   * Add your comments here!!
   */

  getBrowserTimezone(timezones) {
    let zoneId;
    timezones.map((zone) => {
      if (zone.timezoneGenericName === this.getBrowserZone()) {
        this.saveTimeConfigInLocal(zone);
        zoneId = zone;
      }
    });
    return zoneId;
  }

  /**
   * Add your comments here!!
   */

  getCurrentDateObj(timezones, selectedTimezone) {
    const genaric = this.getSameObject(timezones, "timezoneId", selectedTimezone)[0]
      ?.timezoneGenericName;
    const date = this.dateService.getNewDate().toISOString().split(".")[0];
    const formatted = genaric
      ? this.dateService.getZonedStdDate(date, dtFormat.stdDtFormat, genaric)
      : "";
    const formattedTime = genaric
      ? this.dateService.getZonedStdDate(date, dtFormat.hhmma, genaric)
      : "";
    return { formattedDate: formatted, formattedTime };
  }

  /**
   * Add your comments here!!
   */

  gotoErrorPage(code) {
    Emitter.emit(appEvents.openErrorPopup, code);
  }

  /**
   * Add your comments here!!
   */

  getMaxValueOfArray(array, property) {
    let max = array.reduce((acc, shot) => (acc = acc > shot[property] ? acc : shot[property]), 0);
    return max;
  }

  /**
   * Add your comments here!!
   */

  getCurrentLanguage() {
    return this.getFromSession(sessionKEYS.currentLanguage);
  }

  /**
   * Decrypt data
   */

  getUnique(source, property) {
    return source
      ? source.filter((a, i) => source.findIndex((s) => a[property] === s[property]) === i)
      : [];
  }

  /**
   * Decrypt data
   */

  getUnique1D(myArray) {
    return myArray?.filter((value, index, array) => array?.indexOf(value) === index);
  }

  /**
   * Add your comments here!!
   */

  getSameObject(source, property, value) {
    return source?.filter((a) => a[property] == value);
  }

  /**
   * Add your comments here!!
   */

  valueExists(arr, key, valToSearch) {
    return arr.filter((item) => item[key].toLowerCase().includes(valToSearch.toLowerCase()));
  }

  /**
   * Add your comments here!!
   */

  getRandomColor() {
    return "#" + crypto.getRandomValues(new Uint32Array(1))[0].toString(16).padStart(8, 0);
  }

  /**
   * Decrypt data
   */

  replaceArray(source, target, key) {
    for (let i = 0; i < source.length; i++) {
      for (let j = 0; j < target.length; j++) {
        source[i][key] == target[j][key] && (source[i] = target[j]);
      }
    }
    return source ? source : [];
  }

  /**
   * Decrypt data
   */

  concatKeys(array, property, newproperty) {
    let temp = [];
    for (let j = 0; j < array?.length; j++) {
      temp.push(newproperty ? array[j][property] + array[j][newproperty] : array[j][property]);
    }
    return temp;
  }

  /**
   * Decrypt data
   */

  getDiffObjArray(source, property, value) {
    return source?.filter((a) => a[property] != value);
  }

  /**
   * Decrypt data
   */

  removeArray(source, property, value) {
    for (let i = 0; i < source.length; i++) {
      source[i][property] == value && source.splice(i, 1);
    }
    return source;
  }

  /**
   * Decrypt data
   */

  dulplicateCheck(source, key, key2, key3) {
    let target = source.map(function (item) {
      return item[key] + item[key2] + item[key3];
    });
    return target.some((e, i, arr) => arr.indexOf(e) !== i);
  }

  /**
   * Decrypt data
   */

  updateArray(array, key, value) {
    for (let i = 0; i < array.length; i++) {
      array[i][key] = value;
    }
    return array;
  }

  /**
   * Decrypt data
   */

  getStringArray(array, property) {
    let temp = [];
    for (let i = 0; i < array.length; i++) {
      temp.push(array[i][property]);
    }
    return temp;
  }

  /**
   * Decrypt data
   */

  isArraySequential(array) {
    if (array.length < 3) return false;
    return array.every((e, i) => i === 0 || e - 1 === array[i - 1]);
  }

  /**
   * Add your comments here!!
   */

  filterMultiArrays(text, source, subarray, key) {
    let candidates = [];
    for (let i = 0; i < source.length; i++) {
      for (let j = 0; j < source[i][subarray].length; j++) {
        let value = key ? source[i][subarray][j][key] : source[i][subarray][j];
        if (value?.toLowerCase()?.includes(text?.toLowerCase())) {
          candidates.push(source[i]);
          break;
        }
      }
    }
    return text ? candidates : source;
  }

  /**
   * Add your comments here!!
   */

  getUserEmailID() {
    let external = this.getUserAccount()?.idTokenClaims?.enterpriseId;
    if (external && external.includes("@")) return external;
    let internal = this.getUserAccount()?.idTokenClaims?.preferred_username;
    if (internal && internal.includes("@")) return internal;
    let exInternal = this.getUserAccount()?.idTokenClaims?.signInName;
    if (exInternal && exInternal.includes("@")) return exInternal;
  }

  /**
   * Add your comments here!!
   */

  getUsername() {
    let result = this.getUserEmailID();
    return result?.substring(0, result?.indexOf("@"));
  }

  /**
   * Add your comments here!!
   */

  getUserDomain() {
    let email = this.getUserEmailID();
    return email?.split("@")[1];
  }

  /**
   * Add your comments here!!
   */

  regexValidation(value, msg, expression) {
    let result = value.match(expression);
    result == null && msg && this.alert(customMsg.invalid + msg, msgMode.warning);
    return result !== null;
  }

  /**
   * Add your comments here!!
   */

  compareObj(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  /**
   * Add your comments here!!
   */

  compareArrays(array1, array2) {
    if (array1.sort().join(",") === array2.sort().join(",")) {
      return false;
    }
    return true;
  }

  /**
   * Add your comments here!!
   */

  getSilentToken() {
    this.adService.getSilentToken(true);
  }

  /**
   * Add your comments here!!
   */

  windowOpen(url) {
    window.open(url, "_blank", "noopener,noreferrer");
  }

  /**
   * Add your comments here!!
   */

  updateScroll(status) {
    let bodyClass = document.body.classList;
    status ? bodyClass.add("show-scroll") : bodyClass.remove("show-scroll");
  }

  /**
   * Add your comments here!!
   */

  overlayFocusIn() {
    window.overlayFocusIn();
  }

  /**
   * Add your comments here!!
   */

  focusModalHeader() {
    window.focusModalHeader();
  }

  /**
   * Add your comments here!!
   */

  parseToHtml(html) {
    return html ? parse(html) : "";
  }

  /**
   * Add your comments here!!
   */

  openConfirmModal(fun, customMsg) {
    Emitter.emit(appEvents.scheduler.openActionConfirmationModal, {
      proceed: fun,
      message: customMsg ? customMsg : "unsavedDraft"
    });
  }

  /**
   * Add your comments here!!
   */

  bindSelectAll() {
    let control = document.querySelector(
      ".p-multiselect-header > .p-multiselect-select-all > .p-checkbox"
    );
    control.after("Select All");
  }

  /**
   * Add your comments here!!
   */

  customDateSort(sortParams, list, dateParam, timeParam) {
    const sortedData = [...list].sort((value1, value2) => {
      let result = 0;
      const val1 = timeParam ? `${value1[dateParam]} ${value1[timeParam]}` : value1[dateParam];
      const val2 = timeParam ? `${value2[dateParam]} ${value2[timeParam]}` : value2[dateParam];
      const date1 = new Date(val1);
      const date2 = new Date(val2);
      if (date1 < date2) result = -1;
      else if (date1 > date2) result = 1;
      return sortParams.order === 1 ? result : -result;
    });
    return sortedData;
  }

  /**
   * Add your comments here!!
   */

  encodeURI(str) {
    return encodeURIComponent(str).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    );
  }

  /**
   * Add your comments here!!
   */

  decodeURI(str) {
    return decodeURIComponent(str.replace(/\+/g, " "));
  }

  /**
   * Add your comments here!!
   */

  logOut() {
    const userId = this.getUsername();
    this.channel.postMessage(userId);
    return this.adService.logout();
  }
}
