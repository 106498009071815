import React from "react";
import { appEvents, msgMode, summary } from "../services/constants";
import Emitter from "../services/event-emitter";
import { componentTemplate } from "../templates/toaster-html";
import { Toast } from "primereact/toast";
import i18next from "i18next";

class ToasterMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msgRef: React.createRef(null),
      toastRef: React.createRef(Toast)
    };
  }

  /**
   * Add your comments here!!
   */

  componentDidMount() {
    this.registerEvents();
  }

  /**
   * Add your comments here!!
   */

  registerEvents() {
    Emitter.on(appEvents.toastMessages, (data) => {
      this.bindData(data);
      this.resetEvents();
    });
  }

  /**
   *Reset Events
   */

  resetEvents() {
    Emitter.off(appEvents.toastMessages);
    this.registerEvents();
  }

  /**
   *Reset Events
   */

  bindData(data) {
    this.state.toastRef?.current?.clear();
    this.setState({ toastRef: React.createRef(Toast) }, () => {
      let heading = this.translate(this.getSummary(data));
      let detail = this.translate(data.message);
      let payload = this.getObject(heading, data.mode, detail, 10000);
      !data.banner ? this.showMessage(payload) : this.bindBanner(payload);
    });
  }

  /**
   * Bind Message contents
   */

  getObject(heading, mode, detail, life) {
    return {
      severity: mode,
      summary: heading,
      detail: detail,
      life: life
    };
  }

  /**
   * Bind Message contents
   */

  bindBanner(obj) {
    this.state.msgRef.current?.show(obj);
  }

  /**
   * Bind Message contents
   */

  showMessage(obj) {
    this.state.toastRef.current.show(obj);
    window.enableAudio(obj.detail);
  }

  /**
   * Clear all the existing message
   */

  getSummary(data) {
    if (data.summary) return data.summary;
    else if (data?.mode == msgMode.error) return summary.error;
    else if (data?.mode == msgMode.warning) return summary.warn;
    else return this.getCapital(data?.mode);
  }

  /**
   * Clear all the existing message
   */

  translate(value) {
    let result = i18next.t(value);
    return result ? result : value;
  }

  /**
   * Clear all the existing message
   */

  getCapital(value) {
    return value?.charAt(0)?.toUpperCase() + value?.slice(1);
  }

  render() {
    return componentTemplate(this);
  }
}
export default ToasterMessages;
