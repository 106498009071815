/* eslint-disable indent */
import {
  format,
  differenceInMinutes,
  addMinutes,
  subMinutes,
  areIntervalsOverlapping,
  getDay,
  compareAsc
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import React from "react";
import { dtFormat, sessionKEYS, weekOptions } from "./constants";

export class DateService extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   * find the difference between two dates
   */

  findDifferenceInMinutes(dateLeft, dateRight) {
    return dateLeft && dateRight
      ? differenceInMinutes(this.getNewDate(dateLeft), this.getNewDate(dateRight))
      : null;
  }

  /**
   * find the difference between two dates
   */

  addDayValue(value, day) {
    return this.getNewDate(value).setDate(value.getDate() + day);
  }

  /**
   * find the difference between two dates
   */

  addMinutesToDate(time, min) {
    let date = this.getStdDate(new Date()) + " " + time;
    let result = addMinutes(this.getNewDate(date), min);
    return this.formatAMPM(result);
  }

  /**
   * find the difference between two dates
   */

  addMinutesValue(time, min) {
    return addMinutes(
      this.getNewDate(this.getNewDate().toDateString().toString() + " " + time),
      min
    );
  }

  /**
   * Add your comments here!!
   */

  subtractMinutes(time, min) {
    return subMinutes(
      this.getNewDate(this.getNewDate().toDateString().toString() + " " + time),
      min
    );
  }

  /**
   * Add your comments here!!
   */

  clearOffset(date) {
    const time = this.getNewDate(date).getTime() / 1000;
    const offset = this.getNewDate(date).getTimezoneOffset() * 60;
    return this.getNewDate((time - offset) * 1000).toISOString();
  }

  /**
   * Add your comments here!!
   */

  compareDates(date1, date2) {
    return (
      format(this.getNewDate(date1), dtFormat.eeemmmdd) ===
      format(this.getNewDate(date2), dtFormat.eeemmmdd)
    );
  }

  /**
   * fetch the difference between the current IST time and slort start time
   */

  formatToTimestamp(time12h) {
    if (time12h) {
      const [time, modifier] = time12h.split(" ");
      let [hours, minutes] = time.split(":");
      hours === "12" && (hours = "00");
      modifier === "PM" && (hours = parseInt(hours, 10) + 12);
      return `${hours}:${minutes}`;
    }
    return time12h;
  }

  /**
   * Check if time intervals overlap
   */

  checkIfIntervalsOverlap(startDate1, endDate1, startDate2, endDate2) {
    const intervalLeft = { start: this.getNewDate(startDate1), end: this.getNewDate(endDate1) };
    const intervalRight = { start: this.getNewDate(startDate2), end: this.getNewDate(endDate2) };
    return areIntervalsOverlapping(intervalLeft, intervalRight);
  }

  /**
   * fetch the difference between the current IST time and slort start time
   */

  convertToTimezone(date, timezone) {
    if (date) {
      date = this.clearOffset(date);
      timezone = timezone ? timezone : this.getZone();
      return utcToZonedTime(date, timezone);
    }
    return date;
  }

  /**
   * Add your comments here!!
   */

  getZone() {
    let value = sessionStorage.getItem(sessionKEYS.currentTimeGenaricName);
    return value ? JSON.parse(window.atob(value)) : null;
  }

  /**
   * Add your comments here!!
   */

  parseDateString(date) {
    if (typeof date === "string") {
      if (date?.includes("-") && date?.split("-")?.length == 3) {
        date = date?.replaceAll("-", " ");
      }
    }
    return date;
  }

  /**
   * Add your comments here!!
   */

  getStdDate(date, dateFormat) {
    date = this.parseDateString(date);
    dateFormat = dateFormat ? dateFormat : dtFormat.stdDtFormat;
    return date ? format(this.getNewDate(date), dateFormat) : null;
  }

  /**
   * Add your comments here!!
   */

  getDayString(date) {
    let day = getDay(date);
    return weekOptions?.filter((item) => item.key == day)[0];
  }

  /**
   * Add your comments here!!
   */

  getZonedStdDate(date, dateFormat, timezone) {
    dateFormat = dateFormat ? dateFormat : dtFormat.stdDtFormat;
    return date
      ? format(
          timezone ? this.convertToTimezone(date, timezone) : this.convertToTimezone(date),
          dateFormat
        )
      : null;
  }

  /**
   * Add your comments here!!
   */

  getNewDate = (date) => {
    return date ? new Date(date) : new Date();
  };

  /**
   * Add your comments here!!
   */

  getZonedDateObj = (date) => {
    const offsetDate = this.clearOffset(date);
    return utcToZonedTime(offsetDate, this.getZone());
  };

  /**
   * add comment here
   */

  formatAMPM(date, mode) {
    if (date) {
      let hours, minutes;
      if (mode === "change zone") {
        hours = this.convertToTimezone(date)?.getHours();
        minutes = this.convertToTimezone(date)?.getMinutes();
      } else {
        hours = this.getNewDate(date).getHours();
        minutes = this.getNewDate(date).getMinutes();
      }
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      const strTime = (hours < 10 ? "0" + hours : hours) + ":" + minutes + " " + ampm;
      return strTime;
    } else return date;
  }

  /**
   * Add your comments here!!
   */

  getPostDate = (date, time, timeSeparater, dateExtreme, timezone) => {
    let timeStamp;
    if (!timeSeparater && !dateExtreme && time) {
      timeStamp = `${this.getStdDate(date, dtFormat.yymmdd)}T${this.formatToTimestamp(time)}:00`;
    } else {
      timeStamp = `${this.getStdDate(date, dtFormat.yymmdd)}T${this.formatToTimestamp(
        time?.split(timeSeparater)[dateExtreme === "start" ? 0 : 1].trim()
      )}:00`;
    }
    return zonedTimeToUtc(timeStamp, timezone ? timezone : this.getZone()).toISOString();
  };

  /**
   * Add your comments here!!
   */

  getShortMonth(date) {
    return date?.toLocaleString("en-US", { month: "short" });
  }

  /**
   * Add your comments here!!
   */

  getShortWeek(date) {
    return date?.toLocaleString("en-US", {
      weekday: "short"
    });
  }

  /**
   * Add your comments here!!
   */

  getLongWeek(date) {
    return date?.toLocaleString("en-US", {
      weekday: "long"
    });
  }

  /**
   * This function will take input time and create GMT timestamp for payload, no timezone conversion will be done
   */

  getGmtTimeStamp(date, position) {
    const timeStamp = position === "start" ? "T00:00:00.000Z" : "T23:59:59.000Z";
    return `${this.getStdDate(date, dtFormat.yymmdd)}${timeStamp}`;
  }

  /**
   * This will return us the current GMT timestamp required to be sent to API
   */

  getCurrentPostTimestamp() {
    return `${this.getNewDate().toISOString()?.split(".")[0]}.000Z`;
  }

  /**
   * Add your comments here!!
   */

  getDatesInRange(startDate, endDate) {
    const dt = this.getNewDate(startDate.getTime());
    const dates = [];
    while (dt <= endDate) {
      dates.push(this.getNewDate(dt));
      dt.setDate(dt.getDate() + 1);
    }
    return dates;
  }

  /**
   * Add your comments here!!
   */

  isGreaterDate(dateTime) {
    const dtTime = this.getNewDate(dateTime);
    const todayDt = this.getNewDate();
    const comparison = compareAsc(dtTime, todayDt);
    return comparison == -1;
  }

  /**
   * Add your comments here!!
   */

  sortDateArray(source, property) {
    return source.sort(function (a, b) {
      return new Date(a[property]) - new Date(b[property]);
    });
  }
}
