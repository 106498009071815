import React, { Suspense } from "react";

export const componentTemplate = (com) => {
  return (
    <Suspense fallback={<div> </div>}>
      <div className="row header-alert-update-bg">
        <div className="col-12 d-flex justify-content-between">
          {com.props.downtimeData?.configurationvalue}
          <button type="button" className="row-action-btn close" onClick={() => com.closeModal()}>
            <i className="pi pi-times-circle font-20"></i>
          </button>
        </div>
      </div>
    </Suspense>
  );
};
