import React, { Suspense } from "react";
import { Toast } from "primereact/toast";
import { Messages } from "primereact/messages";

export const componentTemplate = (com) => {
  return (
    <Suspense fallback={<div> </div>}>
      {
        <div>
          <Toast ref={com.state.toastRef} baseZIndex="10000" />
          <Messages ref={com.state.msgRef} />
        </div>
      }
    </Suspense>
  );
};
