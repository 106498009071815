/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import {
  nonTabElements,
  sessionKEYS,
  tabElement
} from "../../app/common-module/services/constants";
import i18n from "../translations/i18n";
import React from "react";
/**
 * Add your comments here!!
 */

function addKeydown() {
  window.addEventListener("keydown", handleFirstTab);
}

/**
 * Add your comments here!!
 */

function addMousedown() {
  window.addEventListener("mousedown", handleMouseDownOnce);
}

/**
 * Add your comments here!!
 */

function removeKeydown() {
  window.removeEventListener("keydown", handleFirstTab);
}

/**
 * Add your comments here!!
 */

function removeMousedown() {
  window.removeEventListener("mousedown", handleMouseDownOnce);
}

/**
 * Add your comments here!!
 */

function toggleClass(flag) {
  flag
    ? document.body.classList.add("user-is-tabbing")
    : document.body.classList.remove("user-is-tabbing");
}

/**
 * Add your comments here!!
 */

function handleFirstTab(e) {
  if (e.keyCode === 9) {
    toggleClass(true);
    removeKeydown();
    addMousedown();
  }
}

/**
 * Add your comments here!!
 */

function handleMouseDownOnce() {
  toggleClass(false);
  removeMousedown();
  addKeydown();
}

/**
 * Add your comments here!!
 */

function getNextPrevElement(currentId, shiftKey) {
  let ID = shiftKey ? parseInt(currentId.split("-")[1]) - 1 : parseInt(currentId.split("-")[1]) + 1;
  let element = "overlayitem-" + ID;
  return document.getElementById(element) ? true : false;
}

/**
 * Add your comments here!!
 */

function updateTblIndex(dom) {
  dom.className.includes("p-column-title") && dom.childElementCount > 0 && (dom.tabIndex = -1);
  dom.className.includes("bulk-tabs") && dom.childElementCount > 0 && (dom.children.tabIndex = -1);
}

/**
 * Add your comments here!!
 */

window.updateTabIndex = function () {
  let dom = document.querySelectorAll(tabElement.join());
  for (let i = 0, max = dom.length; i < max; i++) {
    if (nonTabElements.includes(dom[i].className) == false) {
      dom[i].tabIndex = i + 1;
      dom[i].classList.add("focus-me");
      updateTblIndex(dom[i]);
    }
  }
  document.querySelectorAll(".bulk-tabs a").forEach((item) => (item.tabIndex = -1));
  document.querySelectorAll(".p-multiselect-item > span").forEach((item) => (item.tabIndex = -1));
  document
    .querySelectorAll(".screening-disable-slot > span")
    .forEach((item) => (item.tabIndex = -1));
};

/**
 * Add your comments here!!
 */

window.enableAudio = function (message) {
  message = message?.replace(/[^a-zA-Z0-9 ]/g, "");
  if (message && document.body.className.includes("user-is-tabbing")) {
    const synth = window.speechSynthesis;
    const utterThis = new SpeechSynthesisUtterance(message);
    synth.cancel();
    synth.speak(utterThis);
  }
};

/**
 * Add your comments here!!
 */

window.overlayFocusIn = function () {
  window.updateTabIndex();
  let element = document.getElementById("overlayitem-0");
  element?.classList.add("focus-me");
  element?.focus();
  window.enableAudio(element?.innerText);
};

/**
 * Add your comments here!!
 */

window.focusModalHeader = function () {
  window.updateTabIndex();
  let title = document.querySelectorAll("[id=modal-title-head]");
  setTimeout(() => {
    title[title.length - 1]?.focus();
    window.enableAudio(title[title.length - 1]?.innerHTML);
  }, 500);
};

/**
 * Add your comments here!!
 */

window.focusHambergerOnLoad = () => {
  focusSorceBtn(sessionKEYS.hambergerId);
};

/**
 * Add your comments here!!
 */

function focusSorceBtn(key) {
  let btnId = sessionStorage.getItem(key);
  let button = document.getElementById(btnId);
  if (btnId && button) {
    setTimeout(() => {
      button?.focus();
      document.body.click();
      sessionStorage.removeItem(key);
    });
  }
}

/**
 * Add your comments here!!
 */

function focusOverlayItems(evt, shiftKey) {
  if (!getNextPrevElement(evt.target.id, shiftKey)) {
    focusSorceBtn(sessionKEYS.actionSource);
  }
}

/**
 * Add your comments here!!
 */

function focusSSLogo() {
  setTimeout(() => document.getElementById("skipContent").focus(), 100);
}

/**
 * Add your comments here!!
 */

function focusModalHeader() {
  let title = document.querySelectorAll("[id=modal-title-head]");
  setTimeout(() => title[title.length - 1]?.focus(), 200);
}

/**
 * Add your comments here!!
 */

function getTableHeaderFromFilter(tdNode) {
  var idx = [...tdNode.parentNode.children].indexOf(tdNode),
    thCells = tdNode.closest("table").tHead.rows[0].cells,
    th_colSpan_acc = 0;
  for (var i = 0; i < thCells.length; i++) {
    th_colSpan_acc += thCells[i].colSpan;
    if (th_colSpan_acc >= idx + tdNode.colSpan) break;
  }
  return thCells[i];
}

/**
 * Add your comments here!!
 */

function focusMultiselectCheckBox(element) {
  let obj = element?.parentElement?.parentElement?.parentElement;
  if (obj?.className?.includes("p-multiselect-item")) {
    let text = obj?.childNodes[1]?.innerText;
    window.enableAudio(text);
  }
}

/**
 * Add your comments here!!
 */

function checkKeyDownTabFlow(event) {
  if (event.target.id.includes("overlayitem-")) {
    focusOverlayItems(event, event.shiftKey);
  } else if (event.target.className?.includes("modal-footer-last-btn")) {
    event.shiftKey == false && focusModalHeader();
  } else if (event.target.className?.includes("last-element")) {
    event.shiftKey == false && focusSSLogo();
  } else if (event.target.id?.includes("modal-title-head")) {
    event.shiftKey == true && focusLastElement();
  } else if (event.target.id?.includes("skipContent")) {
    event.shiftKey == true && focusPageLastElement();
  } else if (event.target.id?.includes("SelectTime")) {
    event.shiftKey == true && document.getElementById("selectCalendarDate").focus();
  }

  addAreaLabelsForStaticTexts();
}

/**
 * Add your comments here!!
 */

function skipToMainContent() {
  setTimeout(() => document.getElementById("first-main-content")?.focus(), 100);
}

/**
 * Add your comments here!!
 */

function checkKeyDownEnterFlow(event) {
  if (event.target.className?.includes("modal-close-btns")) {
    focusSorceBtn(sessionKEYS.actionSource);
  } else if (event.target.className?.includes("last-element")) {
    focusSSLogo();
  } else if (event.target.className?.includes("hamberger-")) {
    sessionStorage.setItem(sessionKEYS.hambergerId, event.target.id);
  }
  if (event.target.id?.includes("modalsource-")) {
    sessionStorage.setItem(sessionKEYS.actionSource, event.target.id);
  }
  if (event.target.id === "skipContent") skipToMainContent();
}

/**
 * Add your comments here!!
 */

window.getScrollWidth = function () {
  if (window.innerWidth > 768) {
    let width = document.getElementsByClassName("custom-paginator")[0]?.scrollWidth;
    return width - 40 + "px";
  }
};

/**
 * Add your comments here!!
 */

function onkeyupArrowFunctions(elClass, aria, target) {
  let labels = null;
  if (aria == "listbox") {
    let element = target.parentElement.parentElement?.querySelectorAll(".p-dropdown-label")[0];
    labels = element?.innerText;
  } else if (elClass.includes("p-autocomplete-input")) {
    labels = document.querySelectorAll(".p-autocomplete-items .p-highlight")[0]?.innerHTML;
  }
  return labels;
}

/**
 * Add your comments here!!
 */

function onkeyupTabFunctions(elClass, aria, target) {
  addAreaLabelsForStaticTexts();
  var focusedElement = document.activeElement;
  if (target.type == "radio") {
    return i18n.t("select") + " " + target.value;
  } else if (target.type == "checkbox") {
    let parent = target.parentElement;
    return i18n.t(target.checked ? "uncheckSupportPersonnel" : "select") + " " + parent.innerText;
  } else if (elClass.includes("p-inputtext") && !elClass.includes("p-column-filter")) {
    return target.value ? target.value : target.innerText;
  } else if (elClass?.includes("p-checkbox-icon")) {
    focusMultiselectCheckBox(target);
  } else if (elClass.includes("p-inputtext") && elClass.includes("p-column-filter")) {
    let head = getTableHeaderFromFilter(target.parentElement.parentElement.parentElement);
    let text = head?.querySelectorAll(".p-column-title")[0];
    return i18n.t("search") + " " + text?.innerHTML;
  } else if (aria == "listbox") {
    let parent = target.parentElement.parentElement;
    let selecter = parent.className.includes("p-multiselect")
      ? "div.p-multiselect-label"
      : "span.p-dropdown-label";
    let value = parent.querySelector(selecter)?.innerText;
    if (value) {
      return value;
    }
  } else if (
    focusedElement.closest(".p-datepicker-calendar") &&
    focusedElement.tagName === "SPAN"
  ) {
    let abbreviatedName = focusedElement.innerText;
    let fullWeekdayName = getFullWeekdayName(abbreviatedName);
    return fullWeekdayName;
  } else if (focusedElement.closest(".screeningTimeSlotDay") && focusedElement.tagName === "SPAN") {
    let abbreviatedName = focusedElement.innerText.split(" ")[0].trim();
    let abbreviatedDate = focusedElement.innerText.split(" ")[1].trim();
    let fullWeekdayName = getFullWeekdayName(abbreviatedName);
    return fullWeekdayName + abbreviatedDate;
  } else if (
    focusedElement.className.includes("p-datepicker-trigger") &&
    focusedElement.tagName === "BUTTON"
  ) {
    focusedElement.ariaLabel = "calender";
    window.enableAudio(focusedElement.ariaLabel);
  } else if (
    focusedElement.className.includes("p-column-title") &&
    focusedElement.tagName === "SPAN"
  ) {
    const head = focusedElement.innerText;
    switch (head) {
      case i18n.t("noOfCandidates"):
        return i18n.t("numberOfCandidates");
      case i18n.t("noOfInterviewers"):
        return i18n.t("numberOfInterviewers");
      default:
        break;
    }
  } else if (
    focusedElement.className.includes("p-datepicker-prev-icon") &&
    focusedElement.tagName === "SPAN"
  ) {
    focusedElement.ariaLabel = i18n.t("goToPreviousMonth");
    window.enableAudio(i18n.t("goToPreviousMonth"));
  } else if (
    focusedElement.className.includes("p-datepicker-next-icon") &&
    focusedElement.tagName === "SPAN"
  ) {
    focusedElement.ariaLabel = i18n.t("goToNextMonth");
    window.enableAudio(i18n.t("goToNextMonth"));
  } else if (focusedElement.className.includes("row-action-btn close focus-me")) {
    window.enableAudio(i18n.t("closePopup"));
  } else return null;
}

/**
 * Add your comments here!!
 */

function getAriaLabelText(element) {
  return element.getAttribute("aria-label");
}

/**
 * Add your comments here!!
 */

function addAreaLabelsForStaticTexts() {
  const expiringIpIcon = document.querySelector(".expiring-input-icon");
  if (expiringIpIcon) {
    expiringIpIcon.ariaLabel = "expiredDate";
  }
  const list = document.getElementsByClassName("p-row-toggler");
  if (list?.length > 0) {
    for (let i = 0; i < list.length; i++) {
      let condition = list[i].getAttribute("aria-expanded") == "false";
      list[i].ariaLabel = condition ? i18n.t("expandAll") : i18n.t("collapseAll");
    }
  }
}

/**
 * Add your comments here!!
 */

function getInnerText(element) {
  return element.innerText ? element.innerText : element.value;
}

/**
 * Add your comments here!!
 */

document.onkeyup = function (event) {
  if (event.target.nodeName != "BODY" && event.keyCode != 16) {
    if (["Tab", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.code)) {
      let content = getAriaLabelText(event.target);
      let elClass = event.target.className;
      let aria = event.target.getAttribute("aria-haspopup");
      if (event.shiftKey && event.target?.getBoundingClientRect().y < 80) window.scrollBy(0, -80);
      if (!content) {
        if (event.code == "ArrowDown" || event.code == "ArrowUp") {
          content = onkeyupArrowFunctions(elClass, aria, event.target);
        } else if (event.code == "Tab") {
          content = onkeyupTabFunctions(elClass, aria, event.target);
        }
      }
      if (!content) {
        content = getInnerText(event.target);
      }
      if (!content && event.target.placeholder) {
        content = event.target.placeholder;
      }
      window.enableAudio(content);
    }
  }
};

/**
 * Add your comments here!!
 */

document.onkeydown = function (event) {
  window.updateTabIndex();
  if (event.target.nodeName != "BODY" && event.keyCode != 16) {
    if (document.body.classList.contains("user-is-tabbing")) {
      event.key === "Tab" && checkKeyDownTabFlow(event);
      event.code == "Enter" && checkKeyDownEnterFlow(event);
    }
  }
};

/**
 * Add your comments here!!
 */

window.getAccess = function (source, key) {
  var text = "";
  if (source[key]?.Edit == false) {
    text = "india-disable-controls ";
  }
  if (source[key]?.["Edit+Add"] == false) {
    text = text + "add-edit-disabled ";
  }
  return text;
};

addKeydown();

/**
 * Add your comments here!!
 */

function getFullWeekdayName(abbreviatedName) {
  var weekdayMap = {
    [i18n.t("sun")]: i18n.t("S"),
    [i18n.t("mon")]: i18n.t("M"),
    [i18n.t("tue")]: i18n.t("T"),
    [i18n.t("wed")]: i18n.t("W"),
    [i18n.t("thu")]: i18n.t("TH"),
    [i18n.t("fri")]: i18n.t("F"),
    [i18n.t("sat")]: i18n.t("ST")
  };

  return weekdayMap[abbreviatedName] || abbreviatedName;
}

/**
 * Focus last element from the header of the modal on shift+Tab
 */

function focusLastElement() {
  const element = document.querySelector(".modal-footer-last-btn");
  setTimeout(() => {
    element?.focus();
    window.enableAudio(element?.innerHTML);
  }, 100);
}

/**
 * Focus last element of the page
 */
function focusPageLastElement() {
  document.getElementById("pageLastElement").focus();
}
