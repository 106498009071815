import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routePaths } from "./common-module/services/constants";

/**
 * Candidate
 */

const CandidateSlotModule = lazy(() =>
  import("./dashboard-modules/candidate-module/slot-selection/components/slot-view-wrapper")
);

const CandidateDashboardModule = lazy(() =>
  import("./dashboard-modules/candidate-module/dashboard/components/candidate-dashboard-wrapper")
);

/**
 * Schedular
 */

const SchedulerDashboardModule = lazy(() =>
  import(
    "./dashboard-modules/scheduler-dashboard/interview-module/dashboard/components/scheduler-dashboard-wrapper"
  )
);

const SchedulerRequisitionDetailsModule = lazy(() =>
  import(
    "./dashboard-modules/scheduler-dashboard/interview-module/requisition-details/components/requisition-details-wrapper"
  )
);

const SchedulerScreeningDashboardModule = lazy(() =>
  import(
    "./dashboard-modules/scheduler-dashboard/screening-module/dashboard/components/screening-dashboard-wrapper"
  )
);

const SchedulerScreeningDetailsModule = lazy(() =>
  import(
    "./dashboard-modules/scheduler-dashboard/screening-module/screening-details/components/screening-details-wrapper"
  )
);

const SchedulerScreeningAvailabilityModule = lazy(() =>
  import(
    "./dashboard-modules/scheduler-dashboard/screening-module/screening-availability/components/screening-availability-wrapper"
  )
);

const SchedulerBulkUploadModule = lazy(() =>
  import(
    "./dashboard-modules/scheduler-dashboard/interview-module/bulk-upload/components/bulk-upload-wrapper"
  )
);

const SchedulerCandidateCaptureAvailabilityModule = lazy(() =>
  import(
    "./dashboard-modules/scheduler-dashboard/interview-module/candidate-capture-availability/components/candidate-capture-availability-wrapper"
  )
);

/**
 * Interviewer
 */

const InterviewerDashboardModule = lazy(() =>
  import(
    "./dashboard-modules/interviewer-module/dashboard/components/interviewer-dashboard-wrapper"
  )
);

const InterviewerConfirmedSlotsModule = lazy(() =>
  import(
    "./dashboard-modules/interviewer-module//confirmed-slots/components/confirmed-slot-wrapper"
  )
);

const AwaitingSlotsModule = lazy(() =>
  import("./dashboard-modules/interviewer-module/awaiting-slots/components/awaiting-slot-wrapper")
);

const AdvancedAvailabilityModule = lazy(() =>
  import(
    "./dashboard-modules/interviewer-module/advanced-availability/components/advanced-availability-wrapper.js"
  )
);

const JdViewerModule = lazy(() =>
  import(
    "./dashboard-modules/interviewer-module/job-description/components/job-description-wrapper.js"
  )
);

/**
 * Admin
 */

const AdminModule = lazy(() =>
  import(
    "./dashboard-modules/admin-module/user-access-managment/components/user-access-managment-wrapper"
  )
);

const LocationManagement = lazy(() =>
  import(
    "./dashboard-modules/admin-module/location-management/components/location-management-wrapper"
  )
);

const EntityManagement = lazy(() =>
  import("./dashboard-modules/admin-module/entity-management/components/entity-management-wrapper")
);

const MasterDataManagement = lazy(() =>
  import(
    "./dashboard-modules/admin-module/master-data-management/components/master-data-management-wrapper"
  )
);

const ConfigurationTemplateManagement = lazy(() =>
  import(
    "./dashboard-modules/admin-module/configuration-template-management/components/configuration-template-management-wrapper"
  )
);

const OperationalLogs = lazy(() =>
  import("./dashboard-modules/admin-module/operational-logs/components/operational-logs-wrapper")
);

/**
 * Common
 */

const LandingModule = lazy(() => import("./landing-module/dashboard/components/landing"));

class GlobalModule extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="main-container">
        <BrowserRouter>
          <Suspense fallback={<div></div>}>
            <Routes>
              <Route path={routePaths.landing.dashboard} element={<LandingModule />} />
              <Route path={routePaths.admin.dashboard} element={<AdminModule />} />
              <Route
                exact
                path={routePaths.admin.configurationTemplateManagement}
                element={<ConfigurationTemplateManagement />}
              />
              <Route path={routePaths.candidate.dashboard} element={<CandidateDashboardModule />} />
              <Route
                exact
                path={routePaths.candidate.slotSelection}
                element={<CandidateSlotModule />}
              />
              <Route
                path={routePaths.interviewer.dashboard}
                element={<InterviewerDashboardModule />}
              />
              <Route
                path={routePaths.interviewer.confirmedSlots}
                element={<InterviewerConfirmedSlotsModule />}
              />
              <Route path={routePaths.interviewer.jdViewer} element={<JdViewerModule />} />
              <Route path={routePaths.admin.locationManagement} element={<LocationManagement />} />
              <Route path={routePaths.admin.entityManagement} element={<EntityManagement />} />
              <Route
                path={routePaths.admin.masterDataManagement}
                element={<MasterDataManagement />}
              />
              <Route
                path={routePaths.admin.configurationTemplateManagement}
                element={<ConfigurationTemplateManagement />}
              />
              <Route path={routePaths.admin.operationalLogs} element={<OperationalLogs />} />
              <Route
                path={routePaths.interviewer.awaitingSlots}
                element={<AwaitingSlotsModule />}
              />
              \
              <Route
                path={routePaths.interviewer.advancedAvailability}
                element={<AdvancedAvailabilityModule />}
              />
              <Route
                path={routePaths.scheduler.requisitionDetails}
                element={<SchedulerRequisitionDetailsModule />}
              />
              <Route
                path={routePaths.scheduler.bulkUpload}
                element={<SchedulerBulkUploadModule />}
              />
              <Route
                path={routePaths.scheduler.candidateCaptureAvailability}
                element={<SchedulerCandidateCaptureAvailabilityModule />}
              />
              <Route path={routePaths.scheduler.dashboard} element={<SchedulerDashboardModule />} />
              <Route
                path={routePaths.scheduler.screeningDashboard}
                element={<SchedulerScreeningDashboardModule />}
              />
              <Route
                path={routePaths.scheduler.screeningDetails}
                element={<SchedulerScreeningDetailsModule />}
              />
              <Route
                path={routePaths.scheduler.screeningAvailability}
                element={<SchedulerScreeningAvailabilityModule />}
              />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    );
  }
}

export default GlobalModule;
