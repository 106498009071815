import React, { Suspense } from "react";
import { Sidebar } from "primereact/sidebar";

export const componentTemplate = (com) => {
  return (
    <Suspense fallback={<div> </div>}>
      <Sidebar
        visible={com.state.openModal}
        fullScreen
        blockScroll={true}
        className={com.state.className + "-dashboard"}>
        {com.state.code === 403 && (
          <div className="app-error-wrapper d-flex">
            <div className="app-head-section">
              <div className="text-left">
                <span className="app-error-sub-head">Oops!</span>
                <span className="app-error-head">You don’t have access </span>
                <span className="regular-font font-14 mb-4 d-block">
                  Unfortunately, you don’t have permission to access this page.<br></br> Please
                  contact your Recruiter or our Support team for further information
                </span>
                <div className="d-flex">
                  <button
                    aria-label="Home"
                    onClick={() => com.gotoHome()}
                    type="button"
                    className="btn app-error-btn">
                    Home
                  </button>
                  {com.state.supportLink && (
                    <a
                      aria-label="Get Support"
                      className="btn app-error-btn ml-2 w-auto"
                      href={com.state.supportLink}
                      target="_blank"
                      rel="noreferrer">
                      Get Support
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {com.state.code === 500 && (
          <div className="app-error-wrapper d-flex">
            <div className="app-head-section">
              <div className="text-left">
                <span className="app-error-sub-head">We are down for</span>
                <span className="app-error-head">Maintenance</span>
                <span className="regular-font font-14 mb-4 d-block">
                  Our site is currently down for maintenance. We expect to be back soon. Thanks for
                  your patience.
                </span>
                {com.state.supportLink && (
                  <a
                    aria-label="Get Support"
                    className="btn app-error-btn ml-2"
                    href={com.state.supportLink}
                    target="_blank"
                    rel="noreferrer">
                    Get Support
                  </a>
                )}
              </div>
            </div>
          </div>
        )}

        {com.state.code === 401 && (
          <div className="app-error-wrapper d-flex">
            <div className="app-head-section">
              <div className="text-left">
                <span className="app-error-sub-head">Oops!</span>
                <span className="app-error-head">Session Has Expired</span>
                <span className="regular-font font-14 mb-4 d-block">
                  Your an unauthorized user or Your session is valid for a duration of 60 minutes
                  from the time of login.
                </span>
                <button
                  aria-label="Login"
                  type="button"
                  className="btn app-error-btn"
                  onClick={() => com.logout()}>
                  Login
                </button>
                <button
                  aria-label=" Renew Session"
                  type="button"
                  className="btn app-error-btn ml-2 "
                  onClick={() => com.renewAccount()}>
                  Renew Session
                </button>
              </div>
            </div>
          </div>
        )}

        {com.state.code === 404 && (
          <div className="app-error-wrapper-404 d-flex">
            <div className="app-head-section black-text">
              <div className="text-left">
                <span className="app-error-sub-head">Error 404</span>
                <span className="app-error-head">Page not found</span>
                <span className="regular-font font-14 mb-4 d-block">
                  We are sorry, the page you requested could not be found. Please return to the
                  homepage.
                </span>
                <button
                  aria-label="Home"
                  onClick={() => com.gotoHome()}
                  type="button"
                  className="btn app-error-btn">
                  Home
                </button>
              </div>
            </div>
          </div>
        )}

        {(com.state.code === 400 || com.state.code === 405) && (
          <div className="app-error-wrapper d-flex">
            <div className="app-head-section">
              <div className="text-left">
                <span className="app-error-sub-head">Oops!</span>
                <span className="app-error-head">Bad Request</span>
                <span className="regular-font font-13 mb-4 d-block">
                  Bad Request indicates that the server cannot process the request due to something
                  that is perceived to be a client error. Please connect with administrator.
                </span>
                <button
                  aria-label="Home"
                  onClick={() => com.gotoHome()}
                  type="button"
                  className="btn app-error-btn">
                  Home
                </button>
              </div>
            </div>
          </div>
        )}
      </Sidebar>
    </Suspense>
  );
};
