import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { appSettings } from "../../../assets/config/app-config";
import { userTypes, userRoutes, sessionKEYS } from "./constants";

export class AzureAuthService extends React.Component {
  constructor() {
    super();
    this.getAzureConfigurations();
  }

  /**
   * Add your comments here!!
   */

  getAzureConfigurations() {
    let path = this.getRoleFromURL();
    let internal = userRoutes.internal.indexOf(path) !== -1;
    appSettings.azureConfig.auth.redirectUri = window.location.origin + "/" + path;
    this.setAzureConfigurations(internal ? appSettings.azureConfig : appSettings.b2cConfig);
    this.updateReference();
  }

  /**
   * Add your comments here!!
   */

  updateReference() {
    if (this.getAccount()?.idTokenClaims?.aud) {
      let user = this.getUserType();
      let config = user == userTypes.accenture ? appSettings.azureConfig : appSettings.b2cConfig;
      this.setAzureConfigurations(config);
    }
  }

  /**
   * Add your comments here!!
   */

  setAzureConfigurations(config) {
    this.azService = new PublicClientApplication(config);
  }

  /**
   * Add your comments here!!
   */

  getRoleFromURL() {
    return window.location.pathname.split("/")[1];
  }

  /**
   * add comment here
   */

  getAccount() {
    return this.azService.getAllAccounts()[0];
  }

  /**
   * add comment here
   */

  updateIdToken(value, flag) {
    value && sessionStorage.setItem(sessionKEYS.idToken, value.idToken);
    flag && window.location.reload();
  }

  /**
   * add comment here
   */

  getUserType() {
    return this.getAccount()?.idTokenClaims?.aud == appSettings.b2cConfig.auth.clientId
      ? userTypes.external
      : userTypes.accenture;
  }

  /**
   * add comment here
   */

  getUserAccount() {
    let account = this.getAccount();
    account && this.azService.setActiveAccount(account);
    return account;
  }

  /**
   * add comment here
   */

  isAuthenticated() {
    return this.azService.handleRedirectPromise().then((response) => {
      this.updateIdToken(response, false);
      return this.getUserAccount() ? true : false;
    });
  }

  /**
   * add comment here
   */

  getSilentToken() {
    let account = this.getUserAccount();
    if (account) {
      this.azService.acquireTokenSilent({ account: account }).then((response) => {
        this.updateIdToken(response, true);
      });
    }
  }

  /**
   * add comment here
   */

  msalLogin() {
    this.azService.handleRedirectPromise().then(() => {
      this.azService.loginRedirect({ loginHint: null, forceRefresh: true });
    });
  }

  /**
   * add comment here
   */

  logout() {
    return this.azService.logoutRedirect();
  }
}
