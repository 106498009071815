import React from "react";
import { SharedService } from "../services/common.service";
import { componentTemplate } from "../templates/wrapper-html";
import { appEvents, configPayload, httpCode, routePaths, sessionKEYS } from "../services/constants";
import Emitter from "../services/event-emitter";
import "../../../assets/config/tab-config";

class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activateModule: null,
      userEmail: null,
      downtimeData: null
    };
    this.loadCustomServices();
  }

  /**
   * Add your comments here!!
   */

  componentDidMount() {
    this.getConfigurations();
  }

  /**
   * Add your comments here!!
   */

  loadCustomServices() {
    this.sharedService = new SharedService();
  }

  /**
   * Add your comments here!!
   */

  getConfigurations() {
    window.isAuthenticationEnabled ? this.checkAuthentication() : this.getRoleDetails();
  }

  /**
   * Add your comments here!!
   */

  async checkAuthentication() {
    let response = await this.sharedService.isAuthenticated();
    response == true && (this.getEmail(), this.getRoleDetails());
  }

  /**
   * Add your comments here!!
   */

  loadGlobalModule() {
    this.setState({ activateModule: true });
  }

  /**
   * Add your comments here!!
   */

  getEmail() {
    this.setState({ userEmail: this.sharedService.getUserEmailID() });
  }

  /**
   * Add your comments here!!
   */

  gotoDashboard(path, current) {
    !path && this.gotoLanding(routePaths[current].dashboard);
    this.loadGlobalModule();
  }

  /**
   * Add your comments here!!
   */

  gotoLanding(path) {
    window.location.href = window.location.origin + path;
    this.loadGlobalModule();
  }

  /**
   * Add your comments here!!
   */

  openErrorModal() {
    let code = this.sharedService.isRouteValid();
    Emitter.emit(appEvents.openErrorPopup, code ? 403 : 404);
  }

  /**
   * Add your comments here!!
   */

  mapUserRoles(content) {
    this.getConfigurationData();
    let emailDomain = this.sharedService.getUserDomain();
    let roles = content.map((v) => v.toLowerCase());
    let isInternalUser = this.sharedService.isAccentureEmailId(emailDomain);
    isInternalUser && roles.push("landing");
    this.sharedService.keepInSession(sessionKEYS.roleInfo, roles);
    this.authorizeUser(roles, isInternalUser);
  }

  /**
   * Add your comments here!!
   */

  saveConfigurationData(data) {
    this.sharedService.keepInSession(sessionKEYS.configurationData, data);
    this.displayBanner(data);
  }

  /**
   * Add your comments here!!
   */

  displayBanner(data) {
    let obj = this.sharedService.getSameObject(
      data,
      "configurationKey",
      "DeploymentWindowNotification"
    )[0];
    this.setState({ downtimeData: obj });
  }

  /**
   * Add your comments here!!
   */

  authorizeUser(roles, isInternalUser) {
    let current = this.sharedService.getRoleFromURL();
    let path = this.sharedService.getPageFromURL();
    if (current) {
      roles.includes(current) ? this.gotoDashboard(path, current) : this.openErrorModal();
    } else if (!current && !isInternalUser) {
      this.gotoLanding(routePaths.candidate.dashboard);
    } else {
      this.gotoLanding("/" + routePaths.common.landing);
    }
  }

  /**
   * Add your comments here!!
   */

  getConfigObj() {
    return [
      {
        configurationKey: "ThresholdNumberOfCandidatesInPanel"
      },
      {
        configurationKey: "ThresholdNumberOfInterviewersInPanel"
      },
      {
        configurationKey: "DeploymentWindowNotification"
      }
    ];
  }

  /**
   * Add your comments here!!
   */

  getRoleDetails() {
    this.sharedService.getUserRoleDetails().then((response) => {
      response.statusCode === httpCode.sucess && this.mapUserRoles(response.content);
    });
  }

  /**
   * Add your comments here!!
   */

  getConfigurationData() {
    this.sharedService.getConfigurationData(configPayload).then((response) => {
      response.statusCode === httpCode.sucess && this.saveConfigurationData(response.content);
    });
  }

  enableModal = (value) => {
    this.setState({ downtimeData: value });
  };

  /**
   * Add your comments here!!
   */

  render() {
    return componentTemplate(this);
  }
}

export default Wrapper;
